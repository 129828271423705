import React from "react"
import { Link, graphql } from "gatsby"
import MainLayout from "../components/mainlayout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import headerImage1 from "../../src/images/positive_life_header.png";
import headerImage2 from "../../src/images/background.jpg";
import headerImage3 from "../../src/images/body_health_and_fitness_header.png";
import headerImage4 from "../../src/img/body_health_and_life.png";
import headerImage5 from "../../src/images/background.jpg";
import headerImage10 from "../../src/images/life-is-positive-banner.png";
/*import headerImage2 from "../../src/images/background_image_site2.png";*/
import Moment from 'react-moment';
import 'moment-timezone';
import SiteInfo from '../components/siteinformation';
import "bulma/css/bulma.css"

export default ({ data }) => {
    const site = new SiteInfo();
  return (
    <MainLayout>
      <SEO title={site.getPageTitle() + " Home"} />
        <section className="hero is-full-widescreen">
            <div className="hero-body">
                <div className="container">
                    <img src={getBackgroundImage()} alt="placeholder 960" class="title-image" />
                </div>
            </div>
        </section>
{/*        <div class="container-fluid px-0">
            <div class="row">
                <div class="col-xs-12"         style={{
                  width: "100%"
                }}>
                    <img src={getBackgroundImage()} alt="placeholder 960" class="img-responsive" />
                </div>
            </div>
        </div>*/}


            <div class="container">
                <div class="section-title">
                    <h2>Blog Posts</h2>
                </div>
                <div class="section">
                <div class="row columns is-multiline">

      {data.allWordpressPost.edges.map(({ node }) =>  {
          if (getIfRenderPost(node) == false) {
              return;
          }
          return (
                  <div class="column is-one-third">

                      <div class="card large">
                          <div class="card-image">
                              <figure class="image">
                                  <Img fluid={node.featured_media.localFile.childImageSharp.fluid}
                                       key={node.featured_media.localFile.childImageSharp.fluid.src}/>
                              </figure>
                          </div>
                          <div class="cart-content">
                              <div class="recipe-info-warp">
                                  <div class="recipe-info">
                                      <h3><Link to={node.slug}>{node.title}</Link></h3>
                                      <div class="rating">
                                          <Moment format="YYYY/MM/DD HH:MM">{getPostDate(node.date)}</Moment>
                                      </div>
                                  </div>
                              </div>
                              <div dangerouslySetInnerHTML={{__html: node.excerpt}}/>
                          </div>
                      </div>
                  </div>
          )}
      )}

                </div>
                </div>
            </div>


    </MainLayout>
  )
}

function getIfRenderPost(node) {
    var site = new SiteInfo();
    return site.getIfRenderPost(node.categories);
}

function getPostDate(date) {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return new Date( (new Date(date)).getTime() - 24*60*60*1000  - 60*1000 * 8.7);
    }
    if (site == 3) {
        return new Date( (new Date(date)).getTime() - 29*60*60*1000  - 60*1000 * 2.4);
    }
    if (site == 4) {
        return new Date( (new Date(date)).getTime() - 12*60*60*1000  - 60*60*1000 * .58);
    }
    if (site == 5) {
        return new Date( (new Date(date)).getTime() - 6*60*60*1000  - 60*1000 * 22.7);
    }
    return date;
}

function getBackgroundImage() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return headerImage2;
    }
    if (site == 3) {
        return headerImage3;
    }
    if (site == 4) {
        return headerImage4;
    }
    if (site == 5) {
        return headerImage5;
    }
    if (site == 10) {
        return headerImage10;
    }

    return headerImage1;
}

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          categories {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, traceSVG: {}) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }

`